import { useQuery } from "@tanstack/react-query";
import axios from "axios";

// Function to fetch all clients for admin
export const fetchAdminClients = async (isAdmin) => {
  const allClients = await axios.get(
    `/api/clients/`,
    {
      isAdmin: isAdmin,
    },
    { withCredentials: true },
  );

  return allClients.data;
};

// Custom hook to fetch all clients for admin
export default function useAdminClients(isAdmin) {
  return useQuery({
    queryKey: ["adminClients"],
    queryFn: () => fetchAdminClients(isAdmin),
    enabled: !!isAdmin,
  });
}
