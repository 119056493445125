import { useQuery } from "@tanstack/react-query";
import axios from "axios";

// Function to fetch all projects/ data for the datagrid for admin
export const fetchAdminRows = async (isAdmin) => {
  const allRows = await axios.get(
    `/api/admin-rows/`,
    {
      isAdmin: isAdmin,
    },
    { withCredentials: true },
  );
  return allRows.data;
};

// Custom hook to fetch all projects/ data for the datagrid for admin
export default function useAdminRows(isAdmin) {
  return useQuery({
    queryKey: ["adminRows"],
    queryFn: () => fetchAdminRows(isAdmin),
    refetchInterval: 120000,
    gcTime: 1000 * 60 * 60 * 24,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });
}
