import { LicenseInfo } from "@mui/x-license-pro";
import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import "./styles/app.css";

// * Root and default styles for entire application are found at frontend/src/styles/index.css *

// MUI setup/license key initialization
const licenseKey = process.env.REACT_APP_MUI_LICENSE_KEY;
LicenseInfo.setLicenseKey(licenseKey);

// App component with Navbar and Outlet (Login / Dashboard + children)
export default function App() {
  return (
    <div className="app">
      <Navbar />
      <Outlet />
    </div>
  );
}
