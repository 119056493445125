import Container from "@mui/material/Container";
import React, { useContext, useEffect } from "react";
import { DashboardContext } from "../DashboardContext/DashboardContextProvider";
import AdminRequests from "./AdminRequests";
import "./styles/admin-portal.css";

const AdminPortal = () => {
  const { setHeading } = useContext(DashboardContext);
  // Set the heading to "Argus Dashboard" when the component mounts
  useEffect(() => {
    setHeading("Argus Dashboard");
  }, []);
  // Render AdminRequests component
  return (
    <Container id="admin-portal-container" sx={{ width: "100%" }}>
      <AdminRequests />
    </Container>
  );
};

export default AdminPortal;
