import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { RenderSnackbar } from "../../../../SnackbarAlerts/RenderSnackbar";
import "../../../../SnackbarAlerts/styles/render-snackbar.css";
import useCurrentProject from "../../../DashboardComponents/Form/FormQueries/useCurrentProject";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import useQuestionnaire from "../../../QueryHooks/questionnaire/useQuestionnaire";
import useUpdateQuestionnaire from "../../../QueryHooks/questionnaire/useUpdateQuestionnaire";
import useSetRequestStatus from "../../../QueryHooks/request/useSetRequestStatus";
import useInvalidInputs from "../../../QueryHooks/validate/useInvalidInputs";
import PageValues from "./PageValues";
import PdfContainer from "./PdfContainer";
import "./styles/validate-request.css";

// Main Validate Request Component
const ValidateRequest = () => {
  useEffect(() => setHeading("Validate Questionnaire"), []);
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const [invalidInputs, setInvalidInputs] = useState([]);
  const [hoveredChipName, setHoveredChipName] = useState(null);
  const [pdfType, setPdfType] = useState("");
  const [openBaseSnackbar, setOpenBaseSnackbar] = useState(false);
  const [baseSnackbarMessage, setBaseSnackbarMessage] = useState("");
  const [baseSnackbarSeverity, setBaseSnackbarSeverity] = useState("success");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [clearInvalid, setClearInvalid] = useState(false);
  const [submittingUser, setSubmittingUser] = useState({
    id: "",
    email: "",
    name: "",
  });

  // Dashboard Context
  const { setHeading, navigate, clientId, projectId, isAdmin, queryClient } =
    useContext(DashboardContext);

  // Form Data Query
  const { data: formData, isFetched: formFetched } = useCurrentProject(
    clientId,
    projectId,
  );

  // Questionnaire Query
  const {
    data: questionnaire,
    isFetched: questionnaireFetched,
    isFetching: questionnaireFetching,
  } = useQuestionnaire(clientId, projectId);

  // Update Questionnaire Mutation
  const updateQuestionnaire = useUpdateQuestionnaire(clientId, projectId);

  // Invalid Inputs Query
  const { data: invalidData, isFetched: invalidFetched } = useInvalidInputs(
    clientId,
    projectId,
  );

  // Email client Mutation
  const { mutateAsync: emailClient } = useMutation({
    mutationFn: async () => {
      try {
        const res = await axios.post(
          `/api/client-notification/${clientId}/${projectId}/`,
          { location: invalidInputs.length > 0 ? "revisions" : "completed" },
          {
            withCredentials: true,
          },
        );

        return res.data;
      } catch (err) {
        console.log("NOTIFY CLIENT ERR: ", err);
      }
    },
    onError: (err) => {
      console.log("NOTIFY CLIENT ERR: ", err);
    },
  });

  const { mutateAsync: generateSwpppNarrative } = useMutation({
    mutationFn: async () => {
      try {
        const res = await axios.patch(
          `/api/swppp-narrative/${clientId}/${projectId}/`,
          { withCredentials: true },
        );

        return res.data;
      } catch (err) {
        console.log("GENERATE SWPPP NARRATIVE ERR: ", err);
      }
    },
    onError: (err) => {
      console.log("GENERATE SWPPP NARRATIVE ERR: ", err);
    },
  });

  // Mutation to update the request status to "C" or "RV" based on the invalid inputs
  const submitRequestStatus = useSetRequestStatus(
    clientId,
    projectId,
    emailClient,
    generateSwpppNarrative,
    navigate,
  );

  const adminReview = useMutation({
    mutationFn: async (status) => {
      try {
        console.log("ADMIN REVIEW STATUS: ", status);
        const res = await axios.patch(
          `/api/admin-review/${clientId}/${projectId}/`,
          { requestStatus: status },
          { withCredentials: true },
        );

        return res.data;
      } catch (err) {
        console.log("ADMIN REVIEW ERR: ", err);
      }
    },
    onError: (err) => {
      console.log("ADMIN REVIEW ERR: ", err);
      setBaseSnackbarMessage("Error updating request");
      setBaseSnackbarSeverity("error");
      setOpenBaseSnackbar(() => true);
    },
    onSuccess: (data) => {
      setBaseSnackbarMessage("Request updated");
      setBaseSnackbarSeverity("success");
      setOpenBaseSnackbar(() => true);
      setConfirmOpen(false);
      navigate(`/dashboard/admin`, { replace: true });
    },
  });

  // useEffect to determine the request submitter
  useEffect(() => {
    // const getSubmittingUser = async (name, email, phone) => {
    //   if (!clientId) return;
    //   const res = await axios.post(
    //     `/api/user-check/${clientId}/`,
    //     {
    //       name: name,
    //       email: email,
    //       phone: phone,
    //     },
    //     { withCredentials: true },
    //   );

    //   if (res.status === 200) {
    //     setSubmittingUser({
    //       id: res.data.id,
    //       name: res.data.name,
    //       email: res.data.email,
    //     });
    //   } else {
    //     console.log("Error getting submitting user: ", res);
    //   }
    // };
    if (formData && formFetched) {
      if (formData.slide1.onNativeLand) {
        setPdfType(() => "-epa");
      } else if (
        formData.slide1.onNativeLand === false &&
        +formData.slide1.totAcr < 1
      ) {
        setPdfType(() => "-swmp");
      } else {
        setPdfType(() => "");
      }
    }
  }, [formData, formFetched]);

  // Function to determine the completion status of the questionnaire
  const submitQuestionnaireStatus = async (e) => {
    e.preventDefault();
    let invalidInputsLength = invalidInputs.length;
    if (invalidInputsLength > 0) {
      // Mutate Request Status to "RV" if inputs are invalid
      try {
        // await submitRequestStatus.mutateAsync("RV");
        await adminReview.mutateAsync("RV");
      } catch (error) {
        setBaseSnackbarMessage(
          "Error updating request status to 'Revisions Needed'",
        );
        setBaseSnackbarMessage("error");
        setOpenBaseSnackbar(() => true);
      } finally {
        // setBaseSnackbarMessage("Request status updated to 'Revisions Needed'");
        // setBaseSnackbarMessage("success");
        // setOpenBaseSnackbar(() => true);
        console.log("FINALLY");
      }
    } else {
      try {
        // Mutate Request Status to "C" if all inputs are valid
        await adminReview.mutateAsync("C");
        // await submitRequestStatus.mutateAsync("C");
      } catch (error) {
        setBaseSnackbarMessage("Error updating request status to 'Complete'");
        setBaseSnackbarMessage("error");
        setOpenBaseSnackbar(() => true);
      } finally {
        // setBaseSnackbarMessage("Request status updated to 'Complete'");
        // setBaseSnackbarMessage("success");
        // setOpenBaseSnackbar(() => true);
        console.log("Finally");
      }
    }
  };

  return formFetched && formData ? (
    <>
      <Modal
        id="submit-questionnaire-modal"
        classes={{
          root: "submit-questionnaire-modal",
          paperClasses: "submit-questionnaire-paper",
        }}
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        aria-labelledby="submit-questionnaire-check-modal"
        aria-describedby="submit-questionnaire-check-modal"
      >
        <div className="submit-questionnaire-modal-content">
          <header id="check-submit-header">
            <h1 id="submit-check-header">Validate Questionnaire</h1>
            <hr id="check-submit-hr" />
          </header>
          <div id="check-submit-body">
            <p id="check-submit-message">
              {invalidInputs.length === 0
                ? "Are you sure you want to submit? This will set the status of the request to 'Complete', and the client will be able to download/view the questionnaire."
                : "Are you sure you want to submit? This will set the status of the request to 'Revise', and the client will be able to edit the form."}
            </p>
            <div id="check-submit-buttons">
              <Button
                id="cancel-submit-questionnaire-btn"
                variant="contained"
                color="primary"
                onClick={() => setConfirmOpen(false)}
              >
                Cancel
              </Button>
              <Button
                id="validate-submit-questionnaire-btn"
                variant="contained"
                color="primary"
                onClick={submitQuestionnaireStatus}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <RenderSnackbar
        open={openBaseSnackbar}
        setOpen={setOpenBaseSnackbar}
        severity={baseSnackbarSeverity}
        message={baseSnackbarMessage}
        autoHide={2500}
        anchor={{ vertical: "bottom", horizontal: "center" }}
        type="baseSnackbar"
      />
      <div id="main-validation-container">
        <PageValues
          clientId={clientId}
          projectId={projectId}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          numPages={numPages}
          formData={formData}
          setHoveredChipName={setHoveredChipName}
          updateQuestionnaire={updateQuestionnaire}
          pdfType={pdfType}
          setOpenBaseSnackbar={setOpenBaseSnackbar}
          setBaseSnackbarSeverity={setBaseSnackbarSeverity}
          setBaseSnackbarMessage={setBaseSnackbarMessage}
          invalidData={invalidData}
          invalidFetched={invalidFetched}
          setConfirmOpen={setConfirmOpen}
          invalidInputs={invalidInputs}
          setInvalidInputs={setInvalidInputs}
          clearInvalid={clearInvalid}
          setClearInvalid={setClearInvalid}
          queryClient={queryClient}
          questionnaireFetching={questionnaireFetching}
        />
        <PdfContainer
          isAdmin={isAdmin}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          numPages={numPages}
          setNumPages={setNumPages}
          hoveredChipName={hoveredChipName}
          updateQuestionnaire={updateQuestionnaire}
          pdfType={pdfType}
          invalidData={invalidData}
          invalidFetched={invalidFetched}
          questionnaire={questionnaire}
          questionnaireFetched={questionnaireFetched}
        />
      </div>
    </>
  ) : (
    <p>Loading...</p>
  );
};

export default ValidateRequest;
