// PageValues.jsx functions
// Function to open the confirm submission modal
export const confirmSubmit = (e, setConfirmOpen) => {
  e.preventDefault();
  // Set Snackbar open to true
  setConfirmOpen(() => true);
  return;
};

export const createInputGroups = (pdfType, setInputGroup) => {
  if (pdfType === "" || pdfType === "-epa") {
    setInputGroup((prev) => {
      return {
        ...prev,
        p1Groups: {
          ...prev.p1Groups,
          oContactName: ["oContactPhone", "oContactTitle", "oContactEmail"],
          odrName: ["odrCompany", "odrTitle", "odrPhone", "odrEmail"],
        },
        p2Groups: {
          ...prev.p2Groups,
          gcName: ["gcAdd", "gcCity", "gcZip", "gcPhone"],
          gcContactName: ["gcContactTitle", "gcContactPhone", "gcContactEmail"],
          gcContact2Name: [
            "gcContact2Title",
            "gcContact2Phone",
            "gcContact2Email",
          ],
          gcContact3Name: [
            "gcContact3Title",
            "gcContact3Phone",
            "gcContact3Email",
          ],
        },
        p3Groups: {
          dcName: ["dcPhone"],
          dcc: ["dccPhone", "dccEmail", "dccTitle"],
          dustAuditContact: ["dustAuditContactEmail"],
        },
        p4Groups: {
          inspectionRecipient1: [
            "inspectionRecipient1Email",
            "inspectionRecipient1Title",
          ],
          inspectionRecipient2: [
            "inspectionRecipient2Email",
            "inspectionRecipient2Title",
          ],
          inspectionRecipient3: [
            "inspectionRecipient3Email",
            "inspectionRecipient3Title",
          ],
          inspectionRecipient4: [
            "inspectionRecipient4Email",
            "inspectionRecipient4Title",
          ],
          inspectingCompany: [
            "inspectorName",
            "inspectorPhone",
            "inspectorEmail",
          ],
          inspectorName: ["inspectorPhone", "inspectorEmail"],
        },
      };
    });
  } else {
    setInputGroup((prev) => {
      return {
        ...prev,
        p1Groups: {
          ...prev.p1Groups,
          waterServicesContact: ["waterServicesPhone", "waterServicesEmail"],
          gcName: ["gcAdd", "gcCity", "gcZip", "gcPhone"],
          projectManager: ["projectManagerPhone", "projectManagerEmail"],
          superintendent: ["superintendentPhone", "superintendentEmail"],
        },
        p3Groups: {
          ...prev.p3Groups,
          inspectionRecipient1: [
            "inspectionRecipient1Email",
            "inspectionRecipient1Title",
          ],
          inspectionRecipient2: [
            "inspectionRecipient2Email",
            "inspectionRecipient2Title",
          ],
          inspectionRecipient3: [
            "inspectionRecipient3Email",
            "inspectionRecipient3Title",
          ],
          inspectionRecipient4: [
            "inspectionRecipient4Email",
            "inspectionRecipient4Title",
          ],
          inspectingCompany: [
            "inspectorName",
            "inspectorPhone",
            "inspectorEmail",
          ],
          inspectorName: ["inspectorPhone", "inspectorEmail"],
        },
      };
    });
  }
};

// Create a function that checks if the current page has any invalid inputs
export const invalidPage = (pageNumber, invalidInputs, pageValues) => {
  if (invalidInputs.length === 0) return false;
  // const pageValues = [page1, page2, page3, page4];
  let keys = Object.keys(pageValues[pageNumber - 1]);
  const invalidNames = invalidInputs.map((input) => input.name);

  for (const key of keys) {
    if (invalidNames.includes(key)) {
      return true;
    }
  }

  return false;
};

// Function to clear all invalid inputs for the current page
export const clearInvalidPageInputs = async (
  pageValues,
  pageNumber,
  invalidInputs,
  deleteInvalidInput,
  setBaseSnackbarMessage,
  setBaseSnackbarSeverity,
  setOpenBaseSnackbar,
) => {
  // const pageValues = [page1, page2, page3, page4];
  const keys = Object.keys(pageValues[pageNumber - 1]);
  const invalidNames = invalidInputs.map((input) => input.name);
  let clearedPage = true;

  for (const key of keys) {
    if (invalidNames.includes(key)) {
      try {
        await deleteInvalidInput.mutateAsync(key);
      } catch (err) {
        console.log(`ERROR DELETING INVALID INPUT (${key}): `, err);
        clearedPage = false;
      }
    }
  }

  if (clearedPage) {
    setBaseSnackbarMessage(
      () => `Successfully cleared all invalid inputs for page ${pageNumber}!`,
    );
    setBaseSnackbarSeverity(() => "success");
    setOpenBaseSnackbar(() => true);
  } else {
    setBaseSnackbarSeverity("error");
    setBaseSnackbarMessage(
      "Error validating one or many fields for this page. Please try again, or manually validate each field",
    );
    setOpenBaseSnackbar(true);
  }
};

// Function to check the values validity
export function checkValidity(name, invalidData) {
  if (invalidData && invalidData.length === 0) return false;
  let invalid = false;

  for (let i = 0; i < invalidData.length; i++) {
    if (invalidData[i].name === name) {
      invalid = true;
      break;
    }
  }

  return invalid;
}
