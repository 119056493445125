import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { updateUser } from "../../../../Redux/Actions/actions";
import { DashboardContext } from "../../DashboardContext/DashboardContextProvider";

// Function to update archived projects
const updateArchivedProjects = async (projectId, archive) => {
  const res = await axios.patch(
    `/api/admin-archive/${projectId}/`,
    { archive: archive },
    { withCredentials: true },
  );

  return res.data;
};

// Custom hook to update archived projects
export const useArchiveProjects = (
  setOpenSnackbar,
  setSnackbarSeverity,
  setSnackbarMessage,
) => {
  const { queryClient } = useContext(DashboardContext);
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: (data) => updateArchivedProjects(data.projectId, data.archive),
    onSuccess: (data, variables) => {
      console.log("ARCHIVE SUCCESS: ", data);
      queryClient.invalidateQueries({
        queryKey: ["adminRows"],
        refetchType: "all",
      });

      dispatch(
        updateUser({
          archivedProjects: data,
        }),
      );

      setSnackbarSeverity("success");
      if (data.includes(variables.projectId)) {
        setSnackbarMessage("Successfully archived project!");
      } else {
        setSnackbarMessage("Successfully unarchived project!");
      }
      setOpenSnackbar(true);
    },
    onError: (error) => {
      console.log(error);

      setSnackbarSeverity("error");
      setSnackbarMessage("Error archiving project! Please try again!");
      setOpenSnackbar(true);
    },
  });
};
