import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../../DashboardContext/DashboardContextProvider";
import useAdminClients from "../../AdminQueries/useAdminClients";
// import "../../SnackbarAlerts/styles/render-snackbar.css";
import "../../../../Tooltip/styles/tooltip.css";
import "./styles/invite-new-user.css";

// Component that displays a visual representation of the email body that will be sent to the user
const EmailBody = ({ message, setMessage, defaultMessage, clientCode }) => {
  return (
    <div
      className="es-wrapper-color"
      style={{
        width: "90%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "5px",
        gap: "10px",
        margin: "0",
        padding: "0",
        backgroundColor: "rgb(245, 245, 245)",
      }}
    >
      <div
        className="es-wrapper"
        style={{
          maxWidth: "95%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "15px",
          margin: "0",
        }}
      >
        <div
          className="es-header"
          style={{ width: "100%", margin: "0", padding: "5px 0" }}
        >
          <div
            className="es-header-body"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "transparent",
              margin: "0",
              padding: "0",
            }}
          >
            <a
              href="https://www.arguscsaz.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "flex",
                height: "70px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src="https://arguscs.com/wp-content/uploads/2023/08/argusfinal.png"
                alt="Argus Logo"
                title="Argus Logo"
                height="60"
                className="adapt-img"
                style={{ margin: "0", padding: "0" }}
              />
            </a>
          </div>
        </div>
        <div
          className="es-content"
          style={{
            width: "98%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "0",
            padding: "0",
            gap: "10px",
          }}
        >
          <div
            className="es-m-txt-c es-text-2442"
            style={{ textAlign: "center" }}
          >
            <h2
              className="es-text-mobile-size-36"
              style={{
                fontSize: "32px",
                fontWeight: "700",
                margin: "0",
                padding: "0",
                fontFamily:
                  "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
              }}
            >
              Access Granted
            </h2>
          </div>

          <div
            className="es-m-p0r es-m-p0l"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              width: "95%",
              margin: "0",
              padding: "0 !important",
            }}
          >
            <p
              style={{
                fontSize: "14px",
                margin: "0",
                padding: "0",
                width: "95%",
                fontFamily:
                  "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
                color: "var(--input-text-color)",
                fontWeight: "500",
              }}
            >
              {defaultMessage}
            </p>
          </div>

          <div
            className="es-m-txt-c es-text-2442"
            style={{ textAlign: "center" }}
          >
            <h2
              className="es-text-mobile-size-36"
              style={{
                fontSize: "24px",
                fontWeight: "700",
                fontFamily:
                  "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
                margin: "0",
                padding: "0",
              }}
            >
              Message from Argus
            </h2>
          </div>

          <div
            className="es-m-p0r es-m-p0l"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "95%",
            }}
          >
            <textarea
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Enter personalized message here, or leave blank to only send the default message above."
              style={{
                width: "95%",
                maxHeight: "200px",
                minHeight: "100px",
                padding: "8px",
                borderRadius: "5px",
                fontSize: "14px",
                fontFamily:
                  "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
                fontWeight: "var(--default-font-weight)",
                color: "var(--input-text-color)",
                resize: "none",
              }}
              value={message}
            />
          </div>
          <div
            className="es-m-txt-c"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "93%",
              height: "fit-content",
              margin: "0",
              padding: "0",
              gap: "10px",
              border: "2px dashed #cccccc",
              borderRadius: "5px",
              textAlign: "center",
            }}
          >
            <h2
              style={{
                fontSize: "22px",
                fontFamily:
                  "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
                fontWeight: "bold",
                margin: "0",
                padding: "0",
              }}
            >
              Code
            </h2>
            <h1
              style={{
                fontSize: "36px",
                fontFamily:
                  "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
                fontWeight: "bold",
                color: "#2d6d87",
                margin: "0",
                padding: "0",
              }}
            >
              {clientCode || "************"}
            </h1>
          </div>
          <div
            className="es-m-txt-c"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "95%",
              margin: "0",
              height: "fit-content",
            }}
          >
            <p
              rel="noopener noreferrer"
              className="es-button"
              style={{
                color: "var(--btn-white-text)",
                backgroundColor: "var(--btn-green)",
                padding: "5px 18px",
                margin: "0",
                borderRadius: "6px",
                fontSize: "16px",
                fontWeight: "bold",
                textDecoration: "none",
                display: "inline-block",
                cursor: "not-allowed",
              }}
            >
              Access
            </p>
          </div>
        </div>
      </div>
      <div
        className="es-footer"
        style={{
          width: "100%",
          backgroundColor: "transparent",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "0",
          padding: "0",
        }}
      >
        <div
          className="es-footer-body"
          style={{ maxWidth: "500px", margin: "0", padding: "5px 0" }}
        >
          <div className="es-social" style={{ textAlign: "center" }}>
            <a href="#">
              <img
                src="https://enftagi.stripocdn.email/content/assets/img/social-icons/circle-colored/facebook-circle-colored.png"
                alt="Facebook"
                width="30"
                height="30"
                style={{ marginRight: "30px" }}
              />
            </a>
            <a href="#">
              <img
                src="https://enftagi.stripocdn.email/content/assets/img/social-icons/circle-colored/linkedin-circle-colored.png"
                alt="LinkedIn"
                width="30"
                height="30"
                style={{ marginRight: "30px" }}
              />
            </a>
            <a href="#">
              <img
                src="https://enftagi.stripocdn.email/content/assets/img/social-icons/circle-colored/tiktok-circle-colored.png"
                alt="TikTok"
                width="30"
                height="30"
                style={{ marginRight: "30px" }}
              />
            </a>
            <a href="#">
              <img
                src="https://enftagi.stripocdn.email/content/assets/img/other-icons/circle-colored/phone-circle-colored.png"
                alt="Phone"
                width="30"
                height="30"
                style={{ marginRight: "30px" }}
              />
            </a>
            <a href="mailto:info@arguscs.com">
              <img
                src="https://enftagi.stripocdn.email/content/assets/img/other-icons/circle-colored/mail-circle-colored.png"
                alt="Email"
                width="30"
                height="30"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

// Component that handles sending a custom invite to a user
const SendCustomInvite = ({
  setSnackbarSeverity,
  setSnackbarMessage,
  setOpenSnackbar,
  openCustomInvite,
  setOpenCustomInvite,
  clientId,
  selectedUser,
  setSelectedUser,
  clientName,
  clientCode,
  setNewUser,
}) => {
  const [message, setMessage] = useState("");
  const [defaultMessage, setDefaultMessage] = useState(
    `Argus is excited to invite you to access ${clientName}'s projects portal. Simply click the link below to instantly sign in, and click the 'New Project' button to beginning filling out our SWPPP services questionnaire. Alternatively, you can visit www.argusazswppp.com and sign in at a later time using the code provided below, along with your name and email address.`,
  );

  // Update the default message when a new client is selected
  useEffect(() => {
    setDefaultMessage(
      `Argus is excited to invite you to access ${clientName}'s projects portal. Simply click the link below to instantly sign in, and click the 'New Project' button to beginning filling out our SWPPP services questionnaire. Alternatively, you can visit www.argusazswppp.com and sign in at a later time using the code provided below, along with your name and email address.`,
    );
  }, [selectedUser]);

  // Mutation to invite a new user
  const inviteNewUserMutation = useMutation({
    mutationFn: async (data) => {
      try {
        const res = await axios.post(
          `/api/invite-user/${data.clientId}/`,
          {
            userId: data.userId,
            userEmail: data.userEmail,
            location: data.location,
            message: data.message,
          },
          {
            withCredentials: true,
          },
        );
        return res;
      } catch (error) {
        console.log("INVITATION ERROR: ", error);
      }
    },
    onSuccess: (data) => {
      setSnackbarSeverity("success");
      setSnackbarMessage("Invitation email has successfully been sent");
      setOpenSnackbar(true);

      setNewUser({
        name: "",
        email: "",
        phone: "",
        title: "",
      });

      setSelectedUser({
        id: "",
        name: "",
        email: "",
        title: "",
      });

      setMessage("");

      setOpenCustomInvite(false);
    },
    onError: (error) => {
      setSnackbarSeverity("error");
      setSnackbarMessage("Error sending user invitation");
      setOpenSnackbar(true);
    },
  });

  // Event handler to send a custom invite to the selected user
  const handleSendCustomInvite = async () => {
    await inviteNewUserMutation.mutateAsync({
      clientId: clientId,
      userId: selectedUser.id,
      userEmail: selectedUser.email,
      userTitle: selectedUser.title,
      location: "dashboard",
      message: message,
    });
  };
  return (
    <Modal
      open={openCustomInvite}
      className="custom-invite-modal"
      onClose={() => {
        setMessage("");
        setOpenCustomInvite(false);
      }}
    >
      <div className="invite-modal-container">
        <EmailBody
          selectedUser={selectedUser}
          message={message}
          setMessage={setMessage}
          defaultMessage={defaultMessage}
          clientCode={clientCode}
        />
        <div id="invite-user-btns">
          <Button
            id="default-message-btn"
            className="default-message-btn"
            onClick={() => {
              setMessage("");
            }}
            variant="contained"
            disabled={message !== ""}
          >
            Clear
          </Button>
          <Button
            id="send-message-btn"
            className="send-message-btn"
            onClick={handleSendCustomInvite}
            variant="contained"
          >
            Send Invite
          </Button>
        </div>
      </div>
    </Modal>
  );
};

// Component that displays a confirmation modal when a user is about to be deleted
const ConfirmDeleteUser = ({
  openConfirmDelete,
  setOpenConfirmDelete,
  deleteUserMutation,
  userId,
}) => {
  return (
    <Modal
      open={openConfirmDelete}
      onClose={() => setOpenConfirmDelete(false)}
      className="confirm-delete-modal"
    >
      <div className="confirm-delete-container">
        <h2 id="confirm-delete-heading">
          Are you sure you want to delete this user?
        </h2>
        <div className="confirm-delete-btns">
          <Button
            id="cancel-delete-btn"
            className="cancel-delete-btn"
            onClick={() => setOpenConfirmDelete(false)}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            id="confirm-delete-btn"
            className="confirm-delete-btn"
            onClick={() => {
              deleteUserMutation.mutate(userId);
            }}
            variant="contained"
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

// Component that displays a list of existing users for the selected client if any exist
const ExistingUsers = ({
  clientId,
  clientName,
  existingUsersFetched,
  existingUsers,
  setOpenCustomInvite,
  setSelectedUser,
  setSnackbarSeverity,
  setSnackbarMessage,
  setOpenSnackbar,
}) => {
  // Return if no users exist for the selected client
  if (
    !existingUsersFetched ||
    (existingUsers && existingUsers.length === 0) ||
    !clientId
  ) {
    return null;
  }

  const { queryClient } = useContext(DashboardContext);

  const [sortedUsers, setSortedUsers] = useState([]);
  const [deleteUserId, setDeleteUserId] = useState("");
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  // Sort the existing users by name
  useEffect(() => {
    if (existingUsers) {
      setSortedUsers(
        existingUsers.sort((a, b) => a.name.localeCompare(b.name)),
      );
    }
  }, [existingUsers]);

  // Delete user mutation
  const deleteUserMutation = useMutation({
    mutationFn: async (userId) => {
      try {
        const res = await axios.delete(
          `/api/client/${clientId}/user/${userId}/`,
          { withCredentials: true },
        );

        return res.data;
      } catch (error) {
        console.log("ERROR DELETING USER: ", error);
      }
    },
    onSuccess: (data) => {
      setSnackbarSeverity("success");
      setSnackbarMessage("User has been successfully deleted");
      setOpenSnackbar(true);
      setOpenConfirmDelete(false);
      queryClient.invalidateQueries({
        queryKey: ["existing-users", clientId],
        refetchType: "active",
      });
    },
    onError: (error) => {
      console.log("ERROR DELETING USER: ", error);
      setSnackbarSeverity("error");
      setSnackbarMessage("Error deleting user. Please try again.");
      setOpenSnackbar(true);
    },
  });

  return (
    <>
      <div className="existing-users-container">
        <header id="existing-users-header">
          <h2 id="existing-users-heading">{`${clientName} Users`}</h2>
        </header>
        <div className="existing-users-list">
          {existingUsers.map((user) => (
            <div key={user.id} className="existing-user">
              <p className="user-data" id="users-name">
                {user.name}
              </p>
              <p className="user-data">{user.phone}</p>
              <p className="user-data">{user.email}</p>
              <div className="existing-user-btns">
                <Button
                  id="invite-user-btn"
                  className="invite-user-btn"
                  variant="contained"
                  onClick={() => {
                    console.log("USER: ", user);
                    setSelectedUser(() => ({
                      id: user.id,
                      name: user.name,
                      email: user.email,
                      title: user.title,
                    }));
                    setOpenCustomInvite(true);
                  }}
                >
                  Invite
                </Button>
                <Button
                  id="delete-user-btn"
                  className="delete-user-btn"
                  variant="contained"
                  onClick={() => {
                    setDeleteUserId(user.id);
                    setOpenConfirmDelete(true);
                  }}
                >
                  Delete
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ConfirmDeleteUser
        openConfirmDelete={openConfirmDelete}
        setOpenConfirmDelete={setOpenConfirmDelete}
        deleteUserMutation={deleteUserMutation}
        userId={deleteUserId}
      />
    </>
  );
};

// Main component that handles creating and inviting new users
const InviteNewUser = () => {
  const {
    isAdmin,
    setHeading,
    handleFormatPhone,
    capitalizeNameAfterSpace,
    setOpenSnackbar,
    setSnackbarSeverity,
    setSnackbarMessage,
    queryClient,
  } = useContext(DashboardContext);
  useEffect(() => setHeading("Create & Invite User"), [setHeading]);
  const [selectedClient, setSelectedClient] = useState("");
  const [clientCode, setClientCode] = useState("");
  const [placeholder, setPlaceholder] = useState("");
  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    phone: "",
    title: "",
  });
  const [openCustomInvite, setOpenCustomInvite] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    id: "",
    name: "",
    email: "",
    title: "",
  });
  // Query Function to get all clients
  const {
    data: clientsData,
    isLoading: clientsIsLoading,
    isFetched: clientsIsFetched,
  } = useAdminClients(isAdmin);

  // Query Function to get all existing users for the selected client
  const { data: existingUsers, isFetched: existingUsersFetched } = useQuery({
    queryKey: ["existing-users", selectedClient],
    enabled: !!selectedClient,
    queryFn: async () => {
      try {
        const res = await axios.get(`/api/client/${selectedClient}/users/`, {
          withCredentials: true,
        });
        return res.data;
      } catch (error) {
        console.log("ERROR FETCHING EXISTING USERS: ", error);
      }
    },
  });

  const [sortedClients, setSortedClients] = useState([]);

  // Mutation to create a new user
  const createNewUserMutation = useMutation({
    mutationFn: async (data) => {
      try {
        const res = await axios.post(
          `/api/admin/new-user/${selectedClient}/`,
          data,
          {
            withCredentials: true,
          },
        );
        return res.data;
      } catch (error) {
        console.log("ERROR CREATING NEW USER: ", error);
      }
    },
    onSuccess: async (data) => {
      setSnackbarSeverity("success");
      setSnackbarMessage("New User Created Successfully");
      setOpenSnackbar(true);

      setSelectedUser({
        id: data.id,
        name: data.name,
        email: data.email,
        title: data.title,
      });
      setOpenCustomInvite(true);
    },
    onError: (error) => {
      console.log("NEW USER ERROR: ", error);
      setSnackbarSeverity("error");
      setSnackbarMessage("Error Creating New User");
      setOpenSnackbar(true);
    },
  });

  const handleCreateAndEmailUser = async (e) => {
    e.preventDefault();

    // Check that the newUser.name field includes a first and last name
    // If not, display an error message and return
    if (newUser.name.split(" ").length < 2) {
      setSnackbarSeverity("error");
      setSnackbarMessage("Please enter a first and last name");
      setOpenSnackbar(true);
      return;
    }
    // Use a regex to ensure newUser.email is a valid email address
    // If not, display an error message and return
    if (
      !newUser.email.match(
        new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/),
      )
    ) {
      setSnackbarSeverity("error");
      setSnackbarMessage("Please enter a valid email address");
      setOpenSnackbar(true);
      return;
    }

    if (newUser.phone.length < 12) {
      setSnackbarSeverity("error");
      setSnackbarMessage("Please enter a valid phone number");
      setOpenSnackbar(true);
      return;
    }

    try {
      await createNewUserMutation.mutateAsync(newUser);
    } catch (error) {
      console.log("ERROR CREATING USER: ", error);
    }
  };

  const titles = [
    "Architect",
    "Area Manager",
    "Contractor",
    "Dust Control Coordinator",
    "Dust Control Manager",
    "Dust Control Supervisor",
    "Dust Monitor",
    "Engineer",
    "Estimator",
    "Foreman",
    "President",
    "Project Administrator",
    "Project Coordinator",
    "Property Developer",
    "Project Director",
    "Project Engineer",
    "Project Executive",
    "Project Manager",
    "Project Supervisor",
    "Regional Director",
    "Regional Manager",
    "Senior Vice President",
    "Site Manager",
    "Superintendent",
    "Vertical Construction Manager",
    "Vice President",
    "Vice President, Building",
    "Vice President, Land",
  ];

  // Sort the clients by name
  useEffect(() => {
    if (clientsData) {
      setSortedClients(
        clientsData.sort((a, b) => a.name.localeCompare(b.name)),
      );
    }
  }, [clientsData]);

  return (
    <>
      <div className="email-client-container">
        <div id="email-client">
          <header id="user-header">
            <h1 id="email-client-heading">Create & Invite New User</h1>
          </header>
          <div className="create-user-container">
            <div className="user-input-container">
              <label className="create-user-label" htmlFor="clients-form">
                Client:
              </label>
              <FormControl
                id="clients-form"
                classes={{ root: "clients-form-root" }}
              >
                <InputLabel
                  id="clients-select-label"
                  classes={{ shrink: "labelShrink" }}
                >
                  Select a Client
                </InputLabel>
                <Select
                  id="clients-select"
                  className="clients-select"
                  variant="standard"
                  value={selectedClient || ""}
                  SelectDisplayProps={{ className: "clients-select-display" }}
                  MenuProps={{
                    className: "clients-select-menu",
                    classes: {
                      list: "clients-select-list",
                      paper: "clients-select-paper",
                    },
                  }}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (value === 0) {
                      setSelectedClient("");
                      setClientCode("");
                    } else {
                      setSelectedClient(value);
                      setClientCode(
                        clientsData.find((client) => client.id === value)
                          .companyCode,
                      );
                    }
                  }}
                  classes={{
                    root: "clients-select-root",
                    select: "clients-select-root-select",
                    icon: "clients-select-root-icon",
                    nativeInput: "clients-select-root-nativeInput",
                  }}
                >
                  <MenuItem
                    key="clear-client"
                    className="clients-select-menu-item"
                    value={0}
                    id="clear-client-item"
                    divider={true}
                    classes={{
                      root: "clients-list-item",
                      selected: "clients-list-item-selected",
                      divider: "clear-client-item-divider",
                    }}
                  >
                    Clear Selection
                    <CloseIcon id="clear-client-icon" />
                  </MenuItem>
                  {clientsIsFetched &&
                    clientsData.map((client, idx) => (
                      <MenuItem
                        key={client.id}
                        className="clients-select-menu-item"
                        value={client.id}
                        id={"client-item"}
                        divider={idx === clientsData.length - 1 ? false : true}
                        classes={{
                          root: "clients-list-item",
                          selected: "clients-list-item-selected",
                          divider: "clients-list-item-divider",
                        }}
                        onMouseOver={async () => {
                          await queryClient.prefetchQuery({
                            queryKey: ["existing-users", client.id],
                            queryFn: async () => {
                              try {
                                const res = await axios.get(
                                  `/api/client/${client.id}/users/`,
                                  {
                                    withCredentials: true,
                                  },
                                );
                                return res.data;
                              } catch (error) {
                                console.log(
                                  "ERROR PREFETCHING EXISTING USERS: ",
                                  error,
                                );
                              }
                            },
                          });
                        }}
                      >
                        {client.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            {selectedClient !== "" && (
              <>
                <div className="user-input-container">
                  <label
                    className="create-user-label"
                    htmlFor="new-user-name-form"
                  >
                    Users Name:
                  </label>
                  <FormControl
                    id="new-user-name-form"
                    classes={{ root: "new-user-form-root" }}
                  >
                    <InputLabel
                      id="new-user-name-label"
                      classes={{ shrink: "user-label-shrink" }}
                    >
                      New User Name
                    </InputLabel>
                    <OutlinedInput
                      id="new-user-name"
                      className="new-user-name"
                      value={newUser.name}
                      onChange={(e) => {
                        let value = capitalizeNameAfterSpace(e.target.value);
                        setNewUser({ ...newUser, name: value });
                      }}
                      classes={{
                        root: "new-user-name-root",
                        input: "new-user-name-input",
                        notchedOutline: "user-notchedOutline",
                      }}
                      autoComplete="off"
                      endAdornment={
                        <Tooltip
                          id="clear-btn-tooltip"
                          classes={{
                            tooltip: "tooltip-clear-btn",
                            popper: "popper-clear-btn",
                            tooltipPlacementTop: "tooltip-top",
                          }}
                          title={newUser.name === "" ? "" : "Clear Field"}
                          placement="top"
                        >
                          <InputAdornment
                            className="user-field-clear-adornment"
                            id="text-adornment"
                            position="end"
                          >
                            <IconButton
                              style={
                                newUser.name === ""
                                  ? { pointerEvents: "none", cursor: "default" }
                                  : { pointerEvents: "auto", cursor: "pointer" }
                              }
                              aria-label="clear input field"
                              onClick={() => {
                                setNewUser({ ...newUser, name: "" });
                              }}
                              onMouseDown={(e) => e.preventDefault()}
                              edge="end"
                              id="clear-icon-button"
                              disabled={newUser.name === "" ? true : false}
                            >
                              <CloseIcon className="close-icon" />
                            </IconButton>
                          </InputAdornment>
                        </Tooltip>
                      }
                    />
                  </FormControl>
                </div>
                <div
                  className="user-input-container"
                  htmlFor="new-user-email-form"
                >
                  <label className="create-user-label">Users Email:</label>
                  <FormControl
                    id="new-user-email-form"
                    classes={{ root: "new-user-form-root" }}
                  >
                    <InputLabel
                      id="new-user-email-label"
                      classes={{ shrink: "user-label-shrink" }}
                    >
                      New User Email
                    </InputLabel>
                    <OutlinedInput
                      id="new-user-email"
                      className="new-user-email"
                      value={newUser.email}
                      onChange={(e) =>
                        setNewUser({
                          ...newUser,
                          email: e.target.value.toLowerCase(),
                        })
                      }
                      autoComplete="off"
                      classes={{
                        root: "new-user-email-root",
                        input: "new-user-email-input",
                        notchedOutline: "user-notchedOutline",
                      }}
                      endAdornment={
                        <Tooltip
                          id="clear-btn-tooltip"
                          classes={{
                            tooltip: "tooltip-clear-btn",
                            popper: "popper-clear-btn",
                            tooltipPlacementTop: "tooltip-top",
                          }}
                          title={newUser.email === "" ? "" : "Clear Field"}
                          placement="top"
                        >
                          <InputAdornment
                            className="user-field-clear-adornment"
                            id="text-adornment"
                            position="end"
                          >
                            <IconButton
                              style={
                                newUser.email === ""
                                  ? { pointerEvents: "none", cursor: "default" }
                                  : { pointerEvents: "auto", cursor: "pointer" }
                              }
                              aria-label="clear input field"
                              onClick={() => {
                                setNewUser({ ...newUser, email: "" });
                              }}
                              onMouseDown={(e) => e.preventDefault()}
                              edge="end"
                              id="clear-icon-button"
                              disabled={newUser.email === "" ? true : false}
                            >
                              <CloseIcon className="close-icon" />
                            </IconButton>
                          </InputAdornment>
                        </Tooltip>
                      }
                    />
                  </FormControl>
                </div>
                <div className="user-input-container">
                  <label
                    className="create-user-label"
                    htmlFor="new-user-phone-form"
                  >
                    Users Phone:
                  </label>
                  <FormControl
                    id="new-user-phone-form"
                    classes={{ root: "new-user-form-root" }}
                  >
                    <InputLabel
                      id="new-user-phone-label"
                      classes={{ shrink: "user-label-shrink" }}
                    >
                      New User Phone
                    </InputLabel>
                    <OutlinedInput
                      id="new-user-phone"
                      className="new-user-phone"
                      value={newUser.phone}
                      autoComplete="off"
                      onChange={(e) => {
                        let value = handleFormatPhone(e.target.value);
                        setNewUser({ ...newUser, phone: value });
                      }}
                      classes={{
                        root: "new-user-phone-root",
                        input: "new-user-phone-input",
                        notchedOutline: "user-notchedOutline",
                      }}
                      endAdornment={
                        <Tooltip
                          id="clear-btn-tooltip"
                          classes={{
                            tooltip: "tooltip-clear-btn",
                            popper: "popper-clear-btn",
                            tooltipPlacementTop: "tooltip-top",
                          }}
                          title={newUser.phone === "" ? "" : "Clear Field"}
                          placement="top"
                        >
                          <InputAdornment
                            className="user-field-clear-adornment"
                            id="text-adornment"
                            position="end"
                          >
                            <IconButton
                              style={
                                newUser.phone === ""
                                  ? { pointerEvents: "none", cursor: "default" }
                                  : { pointerEvents: "auto", cursor: "pointer" }
                              }
                              aria-label="clear input field"
                              onClick={() => {
                                setNewUser({ ...newUser, phone: "" });
                              }}
                              onMouseDown={(e) => e.preventDefault()}
                              edge="end"
                              id="clear-icon-button"
                              disabled={newUser.phone === "" ? true : false}
                            >
                              <CloseIcon className="close-icon" />
                            </IconButton>
                          </InputAdornment>
                        </Tooltip>
                      }
                    />
                  </FormControl>
                </div>
                <div
                  className="user-input-container"
                  htmlFor="new-user-title-form"
                >
                  <label className="create-user-label">Users Title:</label>
                  <div className="predict-title-container">
                    <FormControl
                      id="new-user-title-form"
                      classes={{ root: "new-user-form-root" }}
                    >
                      <InputLabel
                        id="new-user-title-label"
                        classes={{ shrink: "user-label-shrink" }}
                      >
                        User Title (optional)
                      </InputLabel>
                      <OutlinedInput
                        id="new-user-title"
                        className="new-user-title"
                        value={newUser.title}
                        autoComplete="off"
                        onKeyDown={(e) => {
                          if (e.key === "Tab" || e.key === "ArrowRight") {
                            e.preventDefault();
                            setNewUser({
                              ...newUser,
                              title: placeholder,
                            });
                            setPlaceholder("");
                          }
                        }}
                        onChange={(e) => {
                          if (e.target.value === "") {
                            setPlaceholder("");
                          }
                          setNewUser({
                            ...newUser,
                            title: e.target.value,
                          });
                          let lowerCase = e.target.value.toLowerCase();
                          let match = titles.find((title) => {
                            if (!lowerCase) return false;
                            return title.toLowerCase().startsWith(lowerCase);
                          });

                          if (match) {
                            match =
                              e.target.value + match.slice(lowerCase.length);
                            setPlaceholder(match);
                          } else {
                            setPlaceholder("");
                          }
                        }}
                        classes={{
                          root: "new-user-title-root",
                          input: "new-user-title-input",
                          notchedOutline: "user-notchedOutline",
                        }}
                        endAdornment={
                          <Tooltip
                            id="clear-btn-tooltip"
                            classes={{
                              tooltip: "tooltip-clear-btn",
                              popper: "popper-clear-btn",
                              tooltipPlacementTop: "tooltip-top",
                            }}
                            title={newUser.title === "" ? "" : "Clear Field"}
                            placement="top"
                          >
                            <InputAdornment
                              className="user-field-clear-adornment"
                              id="text-adornment"
                              position="end"
                            >
                              <IconButton
                                style={
                                  newUser.title === ""
                                    ? {
                                        pointerEvents: "none",
                                        cursor: "default",
                                      }
                                    : {
                                        pointerEvents: "auto",
                                        cursor: "pointer",
                                      }
                                }
                                aria-label="clear input field"
                                onClick={() => {
                                  setNewUser({ ...newUser, title: "" });
                                  setPlaceholder("");
                                }}
                                onMouseDown={(e) => e.preventDefault()}
                                edge="end"
                                id="clear-icon-button"
                                disabled={newUser.title === "" ? true : false}
                              >
                                <CloseIcon className="close-icon" />
                              </IconButton>
                            </InputAdornment>
                          </Tooltip>
                        }
                      />
                    </FormControl>
                    <input
                      key="title-placeholder"
                      className="title-placeholder"
                      type="text"
                      autoComplete="off"
                      id="title-placeholder"
                      value={placeholder}
                      disabled={true}
                    />
                  </div>
                </div>
              </>
            )}
            <Button
              id="create-user-btn"
              className="create-user-btn"
              onClick={handleCreateAndEmailUser}
              disabled={!selectedClient || !newUser.name || !newUser.email}
              disableElevation={false}
            >
              Create & Invite User
            </Button>
          </div>
        </div>
        <ExistingUsers
          clientId={selectedClient}
          clientName={
            clientsIsFetched && selectedClient && clientsData.length > 0
              ? clientsData.find((client) => client.id === selectedClient)
                  .shortName
              : ""
          }
          existingUsersFetched={existingUsersFetched}
          existingUsers={existingUsers}
          setOpenCustomInvite={setOpenCustomInvite}
          setSelectedUser={setSelectedUser}
          setSnackbarSeverity={setSnackbarSeverity}
          setSnackbarMessage={setSnackbarMessage}
          setOpenSnackbar={setOpenSnackbar}
        />
      </div>
      <SendCustomInvite
        setSnackbarSeverity={setSnackbarSeverity}
        setSnackbarMessage={setSnackbarMessage}
        setOpenSnackbar={setOpenSnackbar}
        openCustomInvite={openCustomInvite}
        setOpenCustomInvite={setOpenCustomInvite}
        selectedUser={selectedUser}
        clientId={selectedClient}
        clientName={
          clientsIsFetched && selectedClient && clientsData.length > 0
            ? clientsData.find((client) => client.id === selectedClient).name
            : ""
        }
        clientCode={clientCode}
        setNewUser={setNewUser}
        setSelectedUser={setSelectedUser}
      />
    </>
  );
};

export default InviteNewUser;
