import { useMutation } from "@tanstack/react-query";
import axios from "axios";

// Function to email user
export const emailUserToProject = async (
  clientId,
  projectId,
  user,
  location,
) => {
  try {
    const res = await axios.post(
      `/api/invite-user/${clientId}/${projectId}/`,
      {
        userId: user.id,
        userEmail: user.email,
        location: location,
      },
      {
        withCredentials: true,
      },
    );

    return res.data;
  } catch (err) {
    console.log("SEND USER EMAIL ERR: ", err);
  }
};

// Custom hook to email user
export default function useInviteUserToProject(
  setOpenEmailSnackbar,
  setEmailSnackbarSeverity,
  setEmailSnackbarMessage,
) {
  return useMutation({
    mutationFn: (data) =>
      emailUserToProject(
        data.clientId,
        data.projectId,
        data.user,
        data.location,
      ),
    onError: (err) => {
      setEmailSnackbarSeverity("error");
      setEmailSnackbarMessage("There was an error emailing this user");
      setOpenEmailSnackbar(true);
    },
    onSuccess: (data, variables) => {
      setEmailSnackbarSeverity("success");
      if (variables.user) {
        setEmailSnackbarMessage(`Successfully emailed ${variables.user.name}`);
      } else if (variables.submittingUser) {
        setEmailSnackbarMessage(
          `Successfully emailed ${variables.submittingUser.name}`,
        );
      }
      setOpenEmailSnackbar(true);
    },
  });
}
